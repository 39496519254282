<section class="hero">
    <div class="hero-logo">
        <a href="/">Assiette de Juliette</a>
    </div>
    <ngb-carousel #carousel [interval]="8000" [showNavigationArrows]="false" [showNavigationIndicators]="false" [animation]="false" >
        <ng-template ngbSlide>
        <div class="carousel-image-block">
            <div class="carousel-image" style="background-image: url('/assets/images/background.jpg');"></div>
        </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="carousel-image-block">
                <div class="carousel-image" style="background-image: url('/assets/images/bord-eten-4.jpg');"></div>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="carousel-image-block">
                <div class="carousel-image" style="background-image: url('/assets/images/foto-interieur.jpg');"></div>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="carousel-image-block">
                <div class="carousel-image" style="background-image: url('/assets/images/foto-eten-2.jpg');"></div>
            </div>
        </ng-template>
    </ngb-carousel>
</section>
<section class="social-media-block">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="social-media-icons">
                    <a href="https://www.facebook.com/Assiette-de-Juliette-110520794875250" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/assiettedejuliette/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content-block about-block">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="about-image-group">
                    <div class="about-image img-1"></div>
                    <div class="about-image img-2"></div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="about-info">
                    <h2>Onze keuken</h2>
                    <p>Chef Julie en Gastvrouw Anna ontvangen U graag in het nieuwe restaurant Assiette de Juliette!</p>
                    <p>Wij serveren een Belgisch-Franse keuken met seizoensgebonden gerechten van topkwaliteit en geven klassiekers een moderne touch.</p>
                    <p>Wees welkom in Assiette de Juliette in het pittoreske centrum van Herzele, Groenlaan 6, waar wordt gegoocheld met smaken en geuren.</p>
                    <a href="#reserveren" class="btn btn-primary">Reserveren</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content-block quote-block">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="quote">
                    <p class="quote-text">Zoals God in Zuid-Frankrijk</p>
                    <p class="quote-author">Julie Baekelandt</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content-block reservatie-block" id="reserveren">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="reservatie-plugin">
                    <h2>Reserveren</h2>
                    <!-- <p>Wij zijn gesloten wegens verlof van woensdag 25 oktober tot en met donderdag 2 november.</p> -->
                    <app-reservatie-plugin></app-reservatie-plugin>
                </div>
            </div>
            <div class="col-md-5">
                <div class="menu-block">
                    <h2>Onze menu's</h2>
                    <div class="menu-group">
                        <p><a href="/assets/files/assiette-de-juliette-menukaart.pdf" class="btn btn-primary" target="_blank">à la carte</a></p>
                        <p><a href="/assets/files/assiette-de-juliette-menu.pdf" class="btn btn-primary" target="_blank">menu</a></p>
                        <p class="menu-info">Tijdens de middag serveren we enkel lunch en in het weekend enkel de menu.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="contact-block">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="contact-options">
                    <a href="mailto:info@assiettedejuliette.be">info@assiettedejuliette.be</a>
                    <a href="tel:+32486645252">+32 486 64 52 52</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content-block opening-block">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="opening-image"></div>
            </div>
            <div class="col-md-6">
                <div class="opening-times">
                    <h2>Openingsuren</h2>
                    <ul>
                        <li>Ma & Di: Gesloten</li>
                        <li>Wo: 19u00 - 21u00</li>
                        <li>Do: 12u00 - 14u00 & 19u00 - 21u00</li>
                        <li>Vr: 12u00 - 14u00 & 19u00 - 21u00</li>
                        <li>Za: 19u00 - 21u00</li>
                        <li>Zo: 12u00 - 14u00</li>
                    </ul>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        </svg>
                        <span>Groenlaan 6, 9950 Herzele</span></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="maps-block">
    <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Groenlaan%206A%20Herzele+(Assiette%20de%20Juliette)&amp;t=&amp;z=11&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
    <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=b1781d17cbed42467141139468ac487fc13d9005'></script>
</section>
<app-footer></app-footer>
<!-- <div class="container">
    <div class="row">
        <div class="col-12">
            <p>home works! <a [routerLink]="['/contact']">contact</a> </p>
        </div>
    </div>
</div> -->
